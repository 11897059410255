/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from "react";
import "../../main.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { reviews } from '../../../../data/reviews';

let REVIEW_WIDTH = window.screen.width;

if (window.screen.width >= 600) {
  REVIEW_WIDTH = 625;
}

export const SliderClients = () => {
  const { i18n, t } = useTranslation();
  const [offset, setOffset] = useState(0);

  const handleLeftArrow = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + REVIEW_WIDTH;

      return Math.min(newOffset, 0);
    });
  };

  const handleRightArrow = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - REVIEW_WIDTH;

      const maxOffset = -(REVIEW_WIDTH * 3);

      return Math.max(newOffset, maxOffset);
    });
  };
  return (
    <>
      <div
        className="home-clients__block container"
        style={{
          transform: `translateX(${offset}px)`,
        }}
      >
        <div className="home-clients__column">
          <div className="home-clients__card">
            <div className="home-clients__top">
              <img
                src="./images/our_clients_page/stars.svg"
                alt=""
                className="home-clients__star"
              />
              <div className="home-clients__salary">{reviews[0].salary}</div>
            </div>

            <p className="home-clients__review">
              {reviews[0].review[i18n.language]}
            </p>

            <div className="home-clients__review-bottom">
              <img
                src={reviews[0].photo}
                alt={reviews[0].name[i18n.language]}
                className="home-clients__client-img"
              />

              <div className="home-clients__review-bottom-info">
                <div className="home-clients__name-wrapper">
                  <span className="home-clients__name">
                    {reviews[0].name[i18n.language]}
                  </span>
                  <a
                    href={reviews[0].linkedin_url}
                    target="_blank"
                    className="home-clients__linkedin-link"
                    rel="noreferrer"
                  >
                    <img
                      src="./images/our_clients_page/linkedin_icon.svg"
                      alt="LinkedIn"
                      className="home-clients__linkedin-icon"
                    />
                  </a>
                </div>

                <div className="home-clients__position">
                  // {reviews[0].position[i18n.language]}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-clients__column">
          <div className="home-clients__card">
            <div className="home-clients__top">
              <img
                src="./images/our_clients_page/stars.svg"
                alt=""
                className="home-clients__star"
              />
              <div className="home-clients__salary">{reviews[1].salary}</div>
            </div>

            <p className="home-clients__review">
              {reviews[1].review[i18n.language]}
            </p>

            <div className="home-clients__review-bottom">
              <img
                src={reviews[1].photo}
                alt={reviews[1].name[i18n.language]}
                className="home-clients__client-img"
              />

              <div className="home-clients__review-bottom-info">
                <div className="home-clients__name-wrapper">
                  <span className="home-clients__name">
                    {reviews[1].name[i18n.language]}
                  </span>
                  <a
                    href={reviews[1].linkedin_url}
                    target="_blank"
                    className="home-clients__linkedin-link"
                    rel="noreferrer"
                  >
                    <img
                      src="./images/our_clients_page/linkedin_icon.svg"
                      alt="LinkedIn"
                      className="home-clients__linkedin-icon"
                    />
                  </a>
                </div>

                <div className="home-clients__position">
                  // {reviews[1].position[i18n.language]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="home-clients__bottom">
          <NavLink to="/reviews">
            <button className="home-clients__button">{t("see_all")}</button>
          </NavLink>
        </div>
      </div>
    </>
  );
}