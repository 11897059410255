/* eslint-disable react/jsx-no-comment-textnodes */
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { reviews } from '../../data/reviews';

import { Header } from "../components/Header";

import "./main.scss";
import { useEffect } from "react";

import { Footer } from "../components/Footer";

export default function Reviews() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="reviews">
      <Header headerClass="header--none-shadow" />

      <main className="main">
        <section className="portfilio-top">
          <div className="portfolio-top__content container">
            <div className="reviews-top__text-block">
              <h2 className="page-title-reviews page-title-reviews--yellow reviews-main__page-title--position">
                {t("reviews")}
              </h2>
            </div>
          </div>
        </section>

        <section className="reviews-main">
          <div className="container">
            <div className="reviews-main__content">
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className={`reviews-main__card ${
                    index % 2 === 0
                      ? "reviews-main__card--position-1"
                      : "reviews-main__card--position-2"
                  } ${
                    index % 4 === 0 || index % 4 === 3
                      ? ""
                      : "reviews-main__card--change-bg-1"
                  }`}
                >
                  <div className="reviews-main__top">
                    <img
                      src="./images/our_clients_page/stars.svg"
                      alt=""
                      className="home-clients__star"
                    />
                    <div className="reviews-main__salary">{review.salary}</div>
                  </div>

                  <p className="reviews-main__review">
                    {review.review[i18n.language]}
                  </p>

                  <div className="reviews-main__review-bottom">
                    <img
                      src={review.photo}
                      alt=""
                      className="reviews-main__client-img"
                    />

                    <div className="reviews-main__review-bottom-info">
                      <div className="home-clients__name-wrapper">
                        <span className="home-clients__name">
                          {review.name[i18n.language]}
                        </span>
                        <a
                          href={review.linkedin_url}
                          target="_blank"
                          rel="noreferrer"
                          className="home-clients__linkedin-link"
                        >
                          <img
                            src="./images/our_clients_page/linkedin_icon.svg"
                            alt="LinkedIn"
                            className="home-clients__linkedin-icon"
                          />
                        </a>
                      </div>

                      <div className="home-clients__position">
                        {review.position[i18n.language]}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
