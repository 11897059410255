export const reviews = [
    {
      name: {
        en: "Tanya Makarukha",
        ru: "Таня Макаруха",
        ua: "Таня Макаруха",
      },
      position: {
        en: "Product Designer",
        ru: "Продуктовый Дизайнер",
        ua: "Продуктовий Дизайнер",
      },
      review: {
        en: "I have been applying since August of last year and have submitted up to 1,000 applications during this time. I had very few interviews, and it felt like I wasn't even being noticed. With UsJobHero's help, I was able to focus more on interview preparation and feel less demotivated when facing rejections. As a result, got a job of my dream!",
        ru: "Я подаю заявки с августа прошлого года и отправила до 1000 заявок за это время. У меня было очень мало интервью, и казалось, что меня вообще не замечают. С помощью UsJobHero я смогла сосредоточиться больше на подготовке к интервью и меньше расстраиваться из-за отказов. В результате получила работу своей мечты!",
        ua: "Я подаю заявки з серпня минулого року і відправила до 1000 заявок за цей час. У мене було дуже мало співбесід, і здавалося, що мене взагалі не помічають. За допомогою UsJobHero я змогла зосередитися більше на підготовці до співбесід і менше засмучуватися через відмови. У результаті отримала роботу своєї мрії!",
      },
      photo: "./images/our_clients_page/review_7_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/tanya-makarukha/",
      salary: "$170k+",
    },
    {
      name: {
        en: "Nikita Voloshenko",
        ru: "Никита Волошенко",
        ua: "Микита Волошенко",
      },
      position: {
        en: "Data/Backend Engineer",
        ru: "Инженер по работе с данными / бэкенд-инженер",
        ua: "Інженер з роботи з даними / бекенд-інженер",
      },
      review: {
        en: "This company helped me find a new job when the IT market was basically dead. You get a personal assistant that applies to jobs that were issued within 24h for the specified parameters. Can you do it yourself? Yes, you can! But this service saved me countless hours and hairs pulled. Got an offer and now working my new dream job.",
        ru: "Эта компания помогла мне найти новую работу, когда рынок IT был практически мёртв. Вы получаете личного помощника, который подаёт заявки на вакансии, опубликованные в течение 24 часов по заданным параметрам. Можете ли вы сделать это сами? Да, можете! Но этот сервис сэкономил мне бесчисленные часы и нервы. Получил предложение и теперь работаю на своей новой работе мечты.",
        ua: "Ця компанія допомогла мені знайти нову роботу, коли ринок IT був практично мертвий. Ви отримуєте особистого помічника, який подає заявки на вакансії, опубліковані протягом 24 годин за заданими параметрами. Чи можете ви зробити це самі? Так, можете! Але цей сервіс зекономив мені безліч годин та нервів. Отримав пропозицію і тепер працюю на своїй новій роботі мрії.",
      },
      photo: "./images/our_clients_page/review_6_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/nikita-voloshenko/",
      salary: "$200k+",
    },
    {
      name: {
        en: "Skyler Bissell",
        ru: "Скайлер Бисселл",
        ua: "Скайлер Бісселл",
      },
      position: {
        en: "Product Manager",
        ru: "Менеджер по продукту",
        ua: "Менеджер по продукту",
      },
      review: {
        en: "This is a game changer! I averaged 3-4 interviews landed per month with zero time spent on applications, which saved me probably hundreds of hours over the 5 months it took to land a job. I used that time to prep for interviews and do other important things in my life. This is the future of job hunting.",
        ru: "Это изменило правила игры! Я получал 3-4 интервью в месяц, не тратя время на заявки, что сэкономило мне сотни часов за 5 месяцев, которые понадобились для получения работы. Я использовал это время, чтобы готовиться к интервью и заниматься другими важными делами в своей жизни. Это будущее поиска работы.",
        ua: "Це змінило правила гри! Я отримував 3-4 співбесіди на місяць, не витрачаючи часу на заявки, що зекономило мені сотні годин за 5 місяців, які знадобилися для отримання роботи. Я використав цей час для підготовки до співбесід та для інших важливих справ у своєму житті. Це майбутнє пошуку роботи.",
      },
      photo: "./images/our_clients_page/review_5_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/skylerbissell/",
      salary: "$130k",
    },
    {
      name: {
        en: "Ivan Tszian",
        ru: "Иван Цзянь",
        ua: "Іван Цзянь",
      },
      position: {
        en: "Product Designer",
        ru: "Продуктовый Дизайнер",
        ua: "Продуктовий Дизайнер",
      },
      review: {
        en: "Great experience with this service! They found me a Product Designer position in just 7 weeks. The offer exceeded my expectations. The support and guidance from the team were top-notch, making the entire process smooth and stress-free. Will definitely come back in the future to boost my career even more.",
        ru: "Отличный опыт работы с этим сервисом! Они нашли мне позицию Продуктового Дизайнера всего за 7 недель. Предложение превзошло мои ожидания. Поддержка и руководство команды были на высшем уровне, что сделало весь процесс гладким и без стресса. Обязательно вернусь в будущем, чтобы еще больше продвинуть свою карьеру.",
        ua: "Чудовий досвід з цим сервісом! Вони знайшли мені посаду Продуктового Дизайнера всього за 7 тижнів. Пропозиція перевершила мої очікування. Підтримка та керівництво команди були на найвищому рівні, що зробило весь процес гладким і без стресу. Обов'язково повернуся в майбутньому, щоб ще більше просунути свою кар'єру.",
      },
      photo: "./images/our_clients_page/review_1_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/ivan-tszian-43a211190/",
      salary: "$150k+",
    },
    {
      name: {
        en: "Andrii Volotskov",
        ru: "Андрей Волоцков",
        ua: "Андрій Волоцков",
      },
      position: {
        en: "Senior Software Engineer",
        ru: "Старший Инженер-программист",
        ua: "Старший Інженер-програміст",
      },
      review: {
        en: "Outsourcing applications to UsJobHero was a game-changer. It freed me from relying on free time or mood. Initially aiming for $100 per interview, after 2 months, each cost just $25. The service increased interviews, improved success, and reduced stress. I recommend it, but stay involved and analyze results.",
        ru: "Аутсорсинг заявок через UsJobHero стал переломным моментом. Это освободило меня от необходимости зависеть от свободного времени или настроения. Изначально я рассчитывал на $100 за интервью, но через 2 месяца каждая стоила всего $25. Сервис увеличил количество интервью, улучшил успех и снизил стресс. Я рекомендую его, но важно оставаться вовлеченным и анализировать результаты.",
        ua: "Аутсорсинг заявок через UsJobHero став переломним моментом. Це звільнило мене від залежності від вільного часу або настрою. Спочатку я розраховував на $100 за співбесіду, але через 2 місяці кожна коштувала лише $25. Сервіс збільшив кількість співбесід, покращив успіх і зменшив стрес. Я рекомендую, але важливо залишатися залученим та аналізувати результати.",
      },
      photo: "./images/our_clients_page/review_2_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/andrii-volotskov-23318b60/",
      salary: "$180k+",
    },
    {
      name: {
        en: "Gleb Vazhentsev",
        ru: "Глеб Важенцев",
        ua: "Гліб Важенцев",
      },
      position: {
        en: "DevOps Engineer",
        ru: "Инженер DevOps",
        ua: "Інженер DevOps",
      },
      review: {
        en: "Working with UsJobHero was a game-changer for my job search. They didn’t just handle applications—they also helped me build and leverage my professional network. Their team crafted personalized follow-up messages for my connections, which led to several interviews. I was surprised by how effective it was! In just one month, I saw a significant increase in interview opportunities. Highly reccomend!",
        ru: "Работа с UsJobHero стала переломным моментом в моем поиске работы. Они не только обрабатывали заявки, но и помогли мне использовать свою профессиональную сеть. Команда создавала персонализированные сообщения для моих контактов, что привело к нескольким интервью. Я был удивлен, насколько это было эффективно! Всего за месяц я заметил значительное увеличение количества интервью. Очень рекомендую!",
        ua: "Робота з UsJobHero стала переломним моментом у моєму пошуку роботи. Вони не тільки обробляли заявки, а й допомогли мені використати свою професійну мережу. Команда створювала персоналізовані повідомлення для моїх контактів, що призвело до кількох співбесід. Я був здивований, наскільки це було ефективно! Всього за місяць я побачив значне зростання кількості співбесід. Дуже рекомендую!",
      },
      photo: "./images/our_clients_page/review_3_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/vazhentsev",
      salary: "$135k+",
    },
    {
      name: {
        en: "Denys Zhak",
        ru: "Денис Жак",
        ua: "Денис Жак",
      },
      position: {
        en: "Software Engineer",
        ru: "Инженер-программист",
        ua: "Інженер-програміст",
      },
      review: {
        en: "I value my time and dislike job hunting hassles. US Job Hero was a game-changer - like a personal job search assistant. They helped me land dozens of interviews, resulting in several competitive offers. Highly recommended for Software Engineers wanting to streamline their job search and focus on what's important.",
        ru: "Я ценю свое время и не люблю хлопоты с поиском работы. Us Job Hero был переломным моментом – как личный помощник в поиске работы. Они помогли мне получить десятки интервью, что привело к нескольким конкурентным предложениям. Настоятельно рекомендую для инженеров-программистов, которые хотят упростить поиск работы и сосредоточиться на важном.",
        ua: "Я ціную свій час і не люблю клопоти з пошуком роботи. Us Job Hero став переломним моментом – як особистий помічник у пошуку роботи. Вони допомогли мені отримати десятки співбесід, що призвело до кількох конкурентних пропозицій. Настійно рекомендую для інженерів-програмістів, які хочуть спростити пошук роботи і зосередитися на важливому.",
      },
      photo: "./images/our_clients_page/review_4_photo.jpeg",
      linkedin_url: "https://www.linkedin.com/in/denyszhak/",
      salary: "$170k+",
    },
  ];